import { handleApi } from "@/untils/handleApi";
import axios from "../config/axios.js";
const myProfile = {
  state: () => ({
    listFaq: {}
  }),
  getters: {},
  mutations: {
    listFaq(state, data) {
      state.userProfile = { ...data };
    }
  },
  actions: {
    async getListFaq(context, payload) {
      const response = await handleApi(
        axios.get("/api/question-answer", {
          params: {
            ...payload
          }
        })
      );
      context.commit("listFaq", response.data);
      return response.data;
    },
    async getFaqDetail(_, id) {
      const response = await handleApi(axios.get("/api/question-answer/" + id));
      return response.data;
    }
  }
};
export default myProfile;
