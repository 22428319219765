<template>
  <div class="w-full py-4">
    <div
      class="notification-detail cursor-pointer"
      :class="[props.size]"
      v-for="(notification, indx) in notificationList"
      :key="'notification' + indx"
      @dblclick="onDblclick(notification)"
    >
      <div class="line" v-if="indx"></div>
      <div class="flex gap-3 justify-start items-center p-4">
        <div class="img-status">
          <img :src="notification.imageUrl" />
        </div>
        <div class="w-full">
          <div class="flex">
            <div class="flex-1 pr-2">
              <span class="font-bold">{{ notification.target + " " }}</span>
              <span v-if="notification.isApplicationNotify">
                <span class="text-blue-main">{{ notification.objectId }}</span>
                {{ notification.action }}
              </span>
              <span v-else>
                {{ notification.action }}
                <span class="text-blue-main">{{ notification.objectId }}</span>
              </span>
            </div>
            <el-badge :is-dot="!notification.read" class="mt-2"> </el-badge>
          </div>
          <p class="text-xs text-neutral-06">
            {{ notification.createdAt && formatDate(notification.createdAt) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { notificationCompasable } from "./compasable";
import moment from "moment-timezone";
import redirectAppDetail from "@/untils/redirectAppDetail";

const { store, STATUS } = notificationCompasable();
const props = defineProps(["size", "infinitive"]);
const notificationList = ref([]);
const emits = defineEmits(["updateTotal", "onDbClick"]);

const getNotificationList = async (payload) => {
  let params = {};
  if (payload) {
    (params.limit = payload.pageSize), (params.offset = (payload.currentPage - 1) * payload.pageSize || 0);
  }
  await store.dispatch("getNotificationList", params).then((response) => {
    notificationList.value = formatData(response.data.newestNotification);
    emits("updateTotal", response.data.totalRecords);
  });
};
const formatData = (data) => {
  return data.map((el) => {
    if ([STATUS.rejected, STATUS.expired, STATUS.approved].includes(el.status)) {
      el.isApplicationNotify = true;
      el.imageUrl = require(`@/assets/icons/notification-icon/${el.status.toLowerCase()}-icon.svg`);
    } else {
      el.isApplicationNotify = false;
      el.imageUrl = el.userImg || require("@/assets/images/avatar-default.png");
    }
    return el;
  });
};
const formatDate = (value) => {
  const time = moment.utc(value);
  const localtz = moment.tz.guess();
  const date = time.clone().tz(localtz).format("DD MMM YYYY - HH:mm:ss");
  return date;
};
defineExpose({
  getNotificationList
});
const onDblclick = async (notification) => {
  notification.appId = notification.applicationId;
  redirectAppDetail(notification);
  emits("onDbClick");
  await store.dispatch("readNotification", { id: notification.notificationId });
};
</script>
<style lang="scss" scoped>
.unseen {
  @apply absolute bottom-1/2 right-3;
}

.line {
  height: 1px;
  @apply bg-gray-300;
}

.notification-detail {
  @apply relative text-sm;

  img {
    @apply w-12 h-12 min-w-[3rem] rounded-full;
  }

  &.small {
    img {
      @apply w-8 h-8 min-w-[2rem] rounded-full;
    }
  }
}
</style>
