import { handleApi } from "@/untils/handleApi";
import axios from "../config/axios.js";
const myProfile = {
  state: () => ({}),
  getters: {},
  mutations: {},
  actions: {
    postForm(_, data) {
      return handleApi(axios.post("/api/contact-us", data));
    }
  }
};
export default myProfile;
