import axios from "../config/axios.js";

const until = {
  state: () => ({}),
  getters: {},
  mutations: {},
  actions: {
    uploadFile(context, formData) {
      return axios.post("/api/file/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    },
    deleteFile(context, fileName) {
      return axios.delete(`/api/file/${fileName}`);
    },
    checkEmailExist(contect, email) {
      return axios.post("/api/auth/check_exist_email", { email });
    }
  }
};

export default until;
