import { firebaseConfig, firebaseValidKey } from "@/config/variables";
import firebase from "firebase/app";
import "firebase/messaging";
import store from "@/store";

let messaging = null;
if (firebaseConfig) {
  firebase.initializeApp(firebaseConfig);
  messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
}
function resceiveMessage() {
  if (!messaging) return;
  return messaging.onMessage((payload) => {
    store.commit("updateNotification", payload);
    return payload;
  });
}
function getToken() {
  if (!messaging) return;
  return messaging.getToken({
    vapidKey: firebaseValidKey
  });
}

async function initFCM(userInfo) {
  const fcmToken = await getToken();
  if (!fcmToken || !userInfo.accessToken) return;
  store.dispatch("updateFCMToken", fcmToken).then((response) => {
    store.commit("updateFirebaseTokenId", response.data.notificationUserTokenId);
  });
}

function removeFCMToken() {
  const notificationUserTokenId = store.state.firebaseTokenId;
  if (!notificationUserTokenId) return;
  store.dispatch("deleteFCMToken", notificationUserTokenId);
}
function checkNotificationPermission(userInfo) {
  const notificationUserTokenId = store.state.firebaseTokenId;
  if (Notification.permission === "granted" && !notificationUserTokenId) {
    initFCM(userInfo);
  } else if (Notification.permission !== "granted" && notificationUserTokenId) {
    removeFCMToken();
  }
}
export { messaging, resceiveMessage, getToken, initFCM, removeFCMToken, checkNotificationPermission };
