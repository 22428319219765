import { handleApi } from "@/untils/handleApi";
import axios from "../config/axios.js";
const dashboard = {
  state: () => ({}),
  getters: {},
  mutations: {},
  actions: {
    getDataChart(context) {
      return handleApi(axios.get("/api/applications/statistics-by-status"));
    },
    getListCategory(context) {
      return handleApi(axios.get("/api/category"));
    },
    getManualList(context) {
      return handleApi(axios.get("/api/user-manual"));
    },
    downloadManualFile(context) {
      return handleApi(axios.get("/api/user-manual/download", { responseType: "arraybuffer" }));
    },
    getApplicationsExpired(context) {
      return handleApi(axios.get("/api/applications/nearly-expired"));
    },
    getNotificationList(context, payload) {
      return handleApi(axios.get("/api/notification", { params: { ...payload } }));
    },
    readNotification(context, payload) {
      return handleApi(axios.put(`/api/notification/${payload.id}`));
    },
    getCompanyActivities(context) {
      return handleApi(axios.get("/api/activity/company-member"));
    }
  }
};
export default dashboard;
