<template>
  <router-view />
  <DocumentViewer v-if="store.state.showDocument"></DocumentViewer>
  <Loading v-if="store.state.loading"></Loading>
</template>
<script setup>
import { onMounted, onUnmounted } from "vue";
import DocumentViewer from "./components/commons/DocumentViewer.vue";
import Loading from "./components/commons/Loading.vue";
import { useStore } from "vuex";
import router from "./router";
import { removeFCMToken } from "@/firebase/init";

// import { checkNotificationPermission } from "@/firebase/init"
const store = useStore();
store.commit("toggleLoading", false);
// checkNotificationPermission(store.state.authentication.userInfo);
const resizeEvent = () => {
  store.commit("changeScreenWidth", window.innerWidth);
};

const signOut = () => {
  store.commit("toggleLoading", true);
  store
    .dispatch("logOut")
    .then(() => {
      removeFCMToken();
      router.push({ name: "getStarted" });
    })
    .finally(() => store.commit("toggleLoading", false));
};

// Check if user dont interact in 30 minutes
let inactivityTimer;

const resetInactivityTimer = () => {
  clearTimeout(inactivityTimer);
  inactivityTimer = setTimeout(() => {
    if (Object.keys(store.state.authentication.userInfo).length) {
      signOut();
    }
  }, 30 * 60 * 1000);
};
resetInactivityTimer();

onMounted(() => {
  window.addEventListener("resize", resizeEvent);
  document.addEventListener("mousemove", resetInactivityTimer);
  document.addEventListener("keypress", resetInactivityTimer);
});
onUnmounted(() => {
  window.removeEventListener("resize", resizeEvent);
  document.removeEventListener("mousemove", resetInactivityTimer);
  document.removeEventListener("keypress", resetInactivityTimer);
});
</script>
<style lang="scss"></style>
