const STATUS = {
  all: null,
  new: "NEW",
  approved: "APPROVED",
  rejected: "REJECTED",
  pending: "PENDING_RESUBMIT",
  revoked: "REVOKED",
  expired: "EXPIRED",
  transferred: "TRANSFERRED",
  draft: "DRAFT",
  renewed: "RENEWED",
  amend: "AMEND",
  apply: "APPLY",
  active: "ACTIVE",
  lock: "LOCK",
  renewal: "RENEWAL",
  update: "UPDATE",
  banned: "BANNED",
  complete: "COMPLETE"
};

export const TRANSACTION_STATUS = {
  new: "New",
  accept: "Accept",
  decline: "Decline",
  review: "Review",
  error: "Error",
  cancel: "Cancel"
};

export default STATUS;
