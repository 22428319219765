import { handleApi } from "@/untils/handleApi";
import axios from "../config/axios.js";
const companyMember = {
  state: () => ({}),
  getters: {},
  mutations: {},
  actions: {
    getListMember(context) {
      return handleApi(axios.get("/v1/company-members"));
    },
    addCompanyMember(context, data) {
      return handleApi(axios.post("/v1/company-members/member", data));
    },
    updateCompanyMember(context, data) {
      return handleApi(axios.put("/v1/company-members/member", data));
    },
    editCompanyMember(context, data) {
      return handleApi(axios.put(`/v1/company-members/profile/${data.id}`, data));
    },
    getListCompany(context, params) {
      return handleApi(axios.get("/api/company", { params }));
    }
  }
};
export default companyMember;
