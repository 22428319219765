export const normalText = (text) => {
  if (text) {
    return text.substr(0, 1).toUpperCase() + text.substr(1).toLowerCase();
  } else return "";
};
export const hasValidRole = (roleList, role) => {
  return roleList.includes(role);
};
export const hasHistory = () => {
  return window.history.length > 1;
};
