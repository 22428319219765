<template>
  <div class="page-nav-bar" :class="showMenu && 'is-active'">
    <div
      class="w-10 h-10 flex justify-center items-center rounded-full bg-neutral-03 hover:bg-blue-background absolute right-2 top-2"
    >
      <img
        class="w-8 h-8 cursor-pointer lg:hidden"
        src="@/assets/icons/active-icon/time-icon.svg"
        alt=""
        @click="toggleMenu"
      />
    </div>
    <div class="page-logo py-4">
      <img class="cursor-pointer" src="@/assets/images/defaultPageLogo.png" alt="" @click="changeRoute('dashboard')" />
    </div>
    <div class="page-menu flex flex-col grow justify-between">
      <div class="menu-list-mobile">
        <div class="menu-item" :class="[route.name === 'FAQS' && 'is-active']" @click="redirectTo('publicFaqs')">
          FAQs
        </div>
        <div
          class="menu-item"
          :class="[route.name === 'contactUs' && 'is-active']"
          @click="redirectTo('publicContactUs')"
        >
          Contact Us
        </div>
        <div class="menu-item flex">
          <el-dropdown class="w-full">
            <span class="text-neutral-06 w-full outline-none">
              {{ $t("language") }}
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  :class="store.state.language.currentLanguage === 'english' && 'is-active'"
                  @click="changeLanguage('english')"
                  >English</el-dropdown-item
                >
                <el-dropdown-item
                  :class="store.state.language.currentLanguage !== 'english' && 'is-active'"
                  @click="changeLanguage('malay')"
                  >Malaysia</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div
          class="menu-item text-neutral-08"
          :class="[route.name === 'signIn' && 'is-active']"
          @click="redirectTo('signIn')"
        >
          {{ $t("sign_in.log_in") }}
        </div>
        <div
          class="menu-item text-neutral-08"
          :class="[route.name === 'signUp' && 'is-active']"
          @click="redirectTo('signUp')"
        >
          {{ $t("get_started.create_account") }}
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="showMenu"
    class="menu-overlay fixed top-0 left-0 bottom-0 right-0 z-10 lg:static"
    @click="showMenu = false"
  ></div>
  <div class="default-page">
    <div class="default-page--header">
      <div class="flex items-center gap-4">
        <div
          class="w-10 h-10 flex justify-center items-center rounded-full bg-neutral-03 hover:bg-blue-background lg:hidden"
        >
          <img
            class="w-8 h-8 cursor-pointer"
            src="@/assets/icons/black-icon/menu-icon.svg"
            alt=""
            @click="toggleMenu"
          />
        </div>
        <img
          class="w-28 md:w-40 cursor-pointer"
          src="@/assets/images/defaultPageLogo.png"
          alt=""
          @click="router.push({ name: 'getStarted' })"
        />
      </div>
      <div class="hidden md:block">
        <MenuLayout></MenuLayout>
      </div>
      <div class="md:hidden">
        <div v-if="route.name === 'signUp'" class="text-neutral-08" @click="redirectTo('signIn')">
          {{ $t("sign_in.log_in") }}
        </div>
        <div v-if="route.name !== 'signUp'" class="text-neutral-08" @click="redirectTo('signUp')">
          <el-button type="primary" class="warning-button text-sm h-full !px-4 !py-3"
            >{{ $t("get_started.create_account") }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="default-page--body bg-neutral-01 sm:bg-white overflow-auto">
      <router-view />
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import MenuLayout from "@/components/layouts/defaultLayout/MenuLayout.vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();
const route = useRoute();
const showMenu = ref(false);
const redirectTo = (name) => {
  if (name) {
    router.push({ name: name });
  }
  showMenu.value = false;
};
const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};
const changeLanguage = (value) => {
  store.commit("changeLanguge", value);
  showMenu.value = false;
};
</script>
<style lang="scss" scoped>
.default-page--header {
  @apply flex justify-between items-center h-20 border-b border-b-neutral-02 p-4;
}

.default-page--body {
  height: calc(100vh - 80px);
}

.page-nav-bar {
  @apply overflow-y-auto w-64 p-6 pt-0 flex flex-col -left-full fixed;
  @apply bg-white top-0 bottom-0;
  transition: all 0.3s ease-in-out;
  z-index: 11;

  &.is-active {
    left: 0;
  }
}

.menu-overlay {
  background: rgba($color: #000000, $alpha: 0.5);
  transition: all 0.3s ease-in-out;
}

.menu-list-mobile {
  @apply flex flex-col;
}

.menu-item {
  @apply text-label text-neutral-06 p-4;
  cursor: pointer;
  &:hover,
  &.is-active {
    @apply text-body bg-blue-background text-neutral-08;
  }
}
</style>
