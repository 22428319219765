import { handleApi } from "@/untils/handleApi";
import axios from "../config/axios.js";
const transactionHistory = {
  actions: {
    getTransactionList(_, params) {
      return handleApi(axios.get("/api/payment/search", { params }));
    },
    getTransactionRecept(_, id) {
      return handleApi(axios.get("/api/payment/download/" + id));
    },
    getTransactionDetail(_, id) {
      return handleApi(axios.get("/api/payment/" + id));
    }
  }
};
export default transactionHistory;
