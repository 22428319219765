import { handleApi } from "@/untils/handleApi";
import axios from "../config/axios.js";
const application = {
  state: () => ({
    isRenewApp: false
  }),
  getters: {},
  mutations: {},
  actions: {
    getListApplication(context, params) {
      // return handleApi(axios.get("/api/applications/client", { params }));
      return handleApi(axios.get("/api/applications/client/clone", { params }));
    },
    getDetailApplication(context, id) {
      return handleApi(axios.get(`/api/applications/${id}/client`));
    },
    createDraftApplication(context) {
      return handleApi(axios.post("/api/applications/draft"));
    },
    saveDraftApplication(context, data) {
      return handleApi(axios.put(`/api/applications/draft/${data.id}`, data));
    },
    deleteDraftApplication(context, payload) {
      return handleApi(axios.delete("/api/applications/draft", { data: payload }));
    },
    submitApplication(context, data) {
      return handleApi(axios.post("/api/applications", data));
    },
    resubmitApplication(context, payload) {
      return handleApi(axios.put(`/api/applications/${payload.id}`, payload.data));
    },
    checkBannedAndIngredient(context, data) {
      return handleApi(axios.post("/api/banned-restrict-product-ingredient/check", data));
    },
    getRenewList(context, params) {
      // return handleApi(axios.get("/api/applications/renewal/client", { params }));
      return handleApi(axios.get("/api/applications/renewal/client/clone", { params }));
    },
    getRenewApplicationDetail(context, id) {
      return handleApi(axios.get(`/api/applications/${id}/renewal/client`));
    },
    renewApplication(context, data) {
      return handleApi(axios.post(`/api/applications/${data.id}/renewal/client`, data));
    },
    getAmendmentApplicationList(context, params) {
      // return handleApi(axios.get("/api/applications/amendment/client", { params }));
      return handleApi(axios.get("/api/applications/amendment/client/clone", { params }));
    },
    getAmendmentApplicationDetail(context, id) {
      return handleApi(axios.get(`/api/applications/${id}/amendment/client`));
    },
    submitAmendApplication(context, payload) {
      return handleApi(axios.put(`/api/applications/${payload.id}/amendment/client`, payload.data));
    },
    submitPromotionTemporary(context, payload) {
      return handleApi(axios.post(`/api/applications/${payload.id}/promotion`, payload.body));
    },
    resubmitPromotionTemporary(context, payload) {
      return handleApi(axios.put(`/api/applications/promotion/${payload.id}`, payload.body));
    },
    submitExpoTemporary(context, body) {
      return handleApi(axios.post("/api/applications/expo", body));
    },
    resubmitExpoTemporary(context, payload) {
      return handleApi(axios.put(`/api/applications/expo/${payload.id}`, payload.body));
    },
    getDetailPromotionTemporary(context, id) {
      return handleApi(axios.get(`/api/applications/promotion/${id}`));
    },
    getDetailExpoTemporary(context, id) {
      return handleApi(axios.get(`/api/applications/expo/${id}`));
    },
    getTermConditional(context) {
      return handleApi(axios.get("/api/contents/declaration-of-importation"));
    },
    submitPayment(_, payload) {
      return handleApi(axios.post("/api/payment/submit", payload));
    },
    getCompanyInfo(_, params) {
      return handleApi(axios.get("/api/company", { params }));
    },
    getHistoryLog(_, id) {
      return handleApi(axios.get(`/api/applications/view_history_application/${id}`));
    }
  }
};
export default application;
