import { createRouter, createWebHistory } from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import store from "../store";
import axios from "../config/axios";
import { hasValidRole } from "@/untils/helper";
import { Roles } from "@/constants/Role";
const routes = [
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "getStarted",
        component: () => import("@/pages/startedPages/getStarted.vue"),
        meta: { requiresAuth: false }
      },
      {
        path: "/authentication",
        component: () => import("@/pages/startedPages/Authentication/index.vue"),
        children: [
          {
            path: "sign-in",
            name: "signIn",
            component: () => import("@/pages/startedPages/Authentication/signIn.vue"),
            meta: { requiresAuth: false }
          },
          {
            path: "sign-up",
            name: "signUp",
            component: () => import("@/pages/startedPages/Authentication/signUp.vue"),
            meta: { requiresAuth: false }
          },
          {
            path: "/recover-password",
            name: "recoverPassword",
            component: () => import("@/pages/startedPages/RecoverPassword/index.vue"),
            meta: { requiresAuth: false }
          },
          {
            path: "account-issues",
            name: "accountIssues",
            component: () => import("@/pages/startedPages/AccountIssues/index.vue"),
            meta: { requiresAuth: false }
          }
        ]
      },
      {
        path: "/contact-us/public",
        component: () => import("@/pages/loginedPages/ContactUs/RequestSupportForm.vue"),
        name: "publicContactUs",
        meta: { requiresAuth: false }
      },
      {
        path: "/faqs/public",
        component: () => import("@/pages/loginedPages/Faq/Information.vue"),
        name: "publicFaqs",
        meta: { requiresAuth: false }
      },
      {
        path: "/term-and-condition",
        component: () => import("@/pages/startedPages/TermAndConditional/index.vue"),
        name: "termAndConditional",
        meta: { requiresAuth: false }
      }
    ]
  },
  {
    path: "/",
    component: AuthenticatedLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/pages/loginedPages/Dashboard/index.vue"),
        meta: { requiresAuth: true, activeRoute: () => true }
      },
      {
        path: "/notification",
        name: "notification",
        component: () => import("@/pages/loginedPages/Notification/index.vue"),
        meta: { requiresAuth: true, activeRoute: () => true }
      },
      {
        path: "/my-profile",
        component: () => import("@/pages/loginedPages/Dashboard/MyProfile/index.vue"),
        children: [
          {
            path: "",
            name: "myProfile",
            component: () => import("@/pages/loginedPages/Dashboard/MyProfile/ViewProfile.vue"),
            meta: { requiresAuth: true, activeRoute: () => true }
          },
          {
            path: "edit-profile",
            name: "editProfile",
            component: () => import("@/pages/loginedPages/Dashboard/MyProfile/EditProfile.vue"),
            meta: { requiresAuth: true, activeRoute: () => true }
          },
          {
            path: "change-email",
            name: "changeEmail",
            component: () => import("@/pages/loginedPages/Dashboard/MyProfile/ChangeEmail.vue"),
            meta: { requiresAuth: true, activeRoute: () => true }
          },
          {
            path: "change-password",
            name: "changePassword",
            component: () => import("@/pages/loginedPages/Dashboard/MyProfile/ChangePassword.vue"),
            meta: { requiresAuth: true, activeRoute: () => true }
          }
        ]
      },

      {
        path: "/new-application",
        name: "newApplication",
        component: () => import("@/pages/loginedPages/NewApplication/index.vue"),
        meta: { requiresAuth: true, activeRoute: () => true }
      },
      {
        path: "/my-application",
        component: () => import("@/pages/loginedPages/MyApplication/index.vue"),
        meta: { requiresAuth: true, activeRoute: () => true },
        children: [
          {
            path: "",
            name: "myApplication",
            component: () => import("@/pages/loginedPages/MyApplication/ListApplication.vue"),
            meta: { requiresAuth: true, activeRoute: () => true }
          },
          {
            path: "detail/:id",
            name: "detailApplication",
            component: () => import("@/pages/loginedPages/MyApplication/Detail/index.vue"),
            meta: { requiresAuth: true, activeRoute: () => true }
          },
          {
            path: "edit/:id",
            name: "editApplication",
            component: () => import("@/pages/loginedPages/MyApplication/EditApplication/index.vue"),
            meta: { requiresAuth: true, activeRoute: () => true }
          },
          {
            path: "dupplicate/:id",
            name: "dupplicateApplication",
            component: () => import("@/pages/loginedPages/MyApplication/DuplicateApplication/index.vue"),
            meta: {
              requiresAuth: true,
              activeRoute: () =>
                hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
            }
          }
        ]
      },
      {
        path: "/renew-application",
        component: () => import("@/pages/loginedPages/RenewApplications/index.vue"),
        meta: {
          requiresAuth: true,
          activeRoute: () =>
            hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
        },
        children: [
          {
            path: "",
            name: "renewApplication",
            component: () => import("@/pages/loginedPages/RenewApplications/ListApplication.vue"),
            meta: {
              requiresAuth: true,
              activeRoute: () =>
                hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
            }
          },
          {
            path: "detail/:id",
            name: "renewDetailApplication",
            component: () => import("@/pages/loginedPages/RenewApplications/Detail.vue"),
            meta: {
              requiresAuth: true,
              activeRoute: () =>
                hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
            }
          }
        ]
      },
      {
        path: "/amend-application",
        component: () => import("@/pages/loginedPages/AmendmentApplication/index.vue"),
        meta: {
          requiresAuth: true,
          activeRoute: () =>
            hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
        },
        children: [
          {
            path: "",
            name: "amendApplicationList",
            component: () => import("@/pages/loginedPages/AmendmentApplication/ListApplication.vue"),
            meta: {
              requiresAuth: true,
              activeRoute: () =>
                hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
            }
          },
          {
            path: "detail/:id",
            name: "amendDetailApplication",
            component: () => import("@/pages/loginedPages/AmendmentApplication/Detail.vue"),
            meta: {
              requiresAuth: true,
              activeRoute: () =>
                hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
            }
          },
          {
            path: "amend/:id",
            name: "amendApplication",
            component: () => import("@/pages/loginedPages/AmendmentApplication/AmendApplication.vue"),
            meta: {
              requiresAuth: true,
              activeRoute: () =>
                hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
            }
          }
        ]
      },
      {
        path: "/temporary-importation",
        component: () => import("@/pages/loginedPages/Temporary/index.vue"),
        meta: {
          requiresAuth: true,
          activeRoute: () =>
            hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
        },
        children: [
          {
            path: "",
            name: "temporary",
            component: () => import("@/pages/loginedPages/Temporary/ListApplication.vue"),
            meta: {
              requiresAuth: true,
              activeRoute: () =>
                hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
            }
          },
          {
            path: "detail/promotion/:id",
            name: "temporaryPromotionDetail",
            component: () => import("@/pages/loginedPages/Temporary/PromotionDetail.vue"),
            meta: {
              requiresAuth: true,
              activeRoute: () =>
                hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
            }
          },
          {
            path: "detail/expo/:id",
            name: "temporaryExpoDetail",
            component: () => import("@/pages/loginedPages/Temporary/ExpoDetail.vue"),
            meta: {
              requiresAuth: true,
              activeRoute: () =>
                hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
            }
          },
          {
            path: "edit/promotion/:id",
            name: "editTemporaryPromotion",
            component: () => import("@/pages/loginedPages/Temporary/EditTemporary/EditPromotion.vue"),
            meta: {
              requiresAuth: true,
              activeRoute: () =>
                hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
            }
          },
          {
            path: "edit/expo/:id",
            name: "editTemporaryExpo",
            component: () => import("@/pages/loginedPages/Temporary/EditTemporary/EditExpo.vue"),
            meta: {
              requiresAuth: true,
              activeRoute: () =>
                hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
            }
          },
          {
            path: "create",
            name: "createTemporary",
            component: () => import("@/pages/loginedPages/Temporary/CreateTemporary/index.vue"),
            meta: {
              requiresAuth: true,
              activeRoute: () =>
                hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
            }
          }
        ]
      },
      {
        path: "/transaction-history",
        meta: {
          requiresAuth: true,
          activeRoute: () => true
        },
        children: [
          {
            path: "",
            name: "transactionHistory",
            component: () => import("@/pages/loginedPages/TransactionHistory/index.vue")
          },
          {
            path: "detail/:id",
            name: "transactionHistoryDetail",
            component: () => import("@/pages/loginedPages/TransactionHistory/Detail.vue")
          }
        ]
      },
      {
        path: "/company-member",
        name: "companyMember",
        component: () => import("@/pages/loginedPages/CompanyMember/index.vue"),
        meta: {
          requiresAuth: true,
          activeRoute: () =>
            hasValidRole([Roles.business, Roles.companyMember], store.state.authentication.userInfo.role)
        }
      },
      {
        path: "/contact-us",
        component: () => import("@/pages/loginedPages/ContactUs/RequestSupportForm.vue"),
        name: "contactUs",
        meta: { requiresAuth: true, activeRoute: () => true }
      },
      {
        path: "/faqs",
        component: () => import("@/pages/loginedPages/Faq/Information.vue"),
        name: "faqs",
        meta: { requiresAuth: true, activeRoute: () => true }
      }
    ]
  },
  {
    path: "/403",
    component: () => import("@/pages/403.vue"),
    name: "403",
    meta: { requiresAuth: false }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (Object.keys(store.state.authentication.userInfo).length) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `${store.state.authentication.userInfo.tokenType} ${store.state.authentication.userInfo.accessToken}`;
      if (to.meta.activeRoute()) {
        return next();
      } else {
        return next({ name: "403" });
      }
    }
    return next({ name: "signIn" });
  }
  next();
});
export default router;
