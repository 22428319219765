import axios from "../config/axios.js";
import { handleApi } from "../untils/handleApi.js";
const authentication = {
  state: () => ({
    userInfo: {}
  }),
  getters: {},
  mutations: {
    setUserInfo(state, value) {
      state.userInfo = { ...value };
    }
  },
  actions: {
    signUp(context, account) {
      return axios.post("/api/auth/sign-up", account);
    },
    logIn({ commit }, account) {
      return handleApi(axios.post("/api/auth/log-in", account)).then((response) => {
        if (response.data.firstLogin) {
          commit("setUserInfo", {
            ...response.data,
            password: account.password
          });
        } else {
          commit("setUserInfo", { ...response.data });
        }
        axios.defaults.headers.common["Authorization"] = `${response.data.tokenType} ${response.data.accessToken}`;
        return response;
      });
    },
    logOut({ commit }) {
      return handleApi(axios.get("/api/auth/log-out")).then((response) => {
        commit("setUserInfo", {});
        commit("changeUserProfile", {}, { root: true });
        axios.defaults.headers.common["Authorization"] = "";
      });
    },
    changePasswordFirstLogin(context, data) {
      return handleApi(axios.put("/api/auth/change-password-first-time", data)).then((response) => {
        if (response.data.changePasswordSuccess) {
          let userData = { ...context.state.userInfo };
          userData.firstLogin = false;
          context.commit("setUserInfo", { ...userData });
        }
        return response;
      });
    },
    acceptTerm(context, data) {
      return handleApi(axios.put("/api/auth/accept-term-and-conditional", data)).then((response) => {
        if (response.data.success) {
          let userData = { ...context.state.userInfo };
          userData.acceptTermAndConditional = true;
          context.commit("setUserInfo", { ...userData });
          axios.defaults.headers.common["Authorization"] = `${response.data.tokenType} ${response.data.accessToken}`;
        }
        return response;
      });
    },
    changePassword(context, data) {
      return handleApi(axios.put("/api/profile/password", data));
    },
    recoverPasswordStep1(context, data) {
      return handleApi(axios.put("/api/auth/reset-password-step-1", data));
    },
    recoverPasswordStep2(context, data) {
      return handleApi(axios.put("/api/auth/reset-password-step-2", data));
    },
    recoverPasswordStep3(context, data) {
      return handleApi(axios.put("/api/auth/reset-password-step-3", data));
    },
    unlockAccount(context, data) {
      return handleApi(axios.post("/api/auth/unlock-account-request", data));
    },
    accountIssueRequest(context, data) {
      return handleApi(axios.post("/api/auth/account-issue-request", data));
    }
  }
};
export default authentication;
