import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import language from "./language";
import authentication from "./authentication";
import myProfile from "./my-profile";
import transactionHistory from "./transaction-history";
import contactUs from "./contact-us";
import faq from "./faq";
import until from "./until";
import application from "./application";
import companyMember from "./company-member";
import dashboard from "./dashboard";
import axios from "../config/axios.js";
import { handleApi } from "@/untils/handleApi";

export default createStore({
  state: {
    showDocument: false,
    documentInfo: {
      preview: false,
      type: "application/pdf",
      source: ""
    },
    loading: false,
    screenWidth: window.innerWidth,
    newNotification: {},
    isReadNotification: true,
    firebaseTokenId: null
  },
  getters: {},
  mutations: {
    toggleShowDocument(state, value) {
      state.showDocument = value;
    },
    changeDocumentInfo(state, data) {
      if (data.preview) {
        state.documentInfo.preview = data.preview;
      }
      state.documentInfo.type = data.type;
      state.documentInfo.source = data.source;
    },
    toggleLoading(state, value) {
      state.loading = value;
      state.documentInfo.preview = false;
    },
    changeScreenWidth(state, value) {
      state.screenWidth = value;
    },
    updateNotification(state, value) {
      state.newNotification = value;
    },
    updateFirebaseTokenId(state, value) {
      state.firebaseTokenId = value;
    },
    updateIsReadNotification(state, value) {
      state.isReadNotification = value;
    }
  },
  actions: {
    updateFCMToken(context, token) {
      return handleApi(axios.put("/api/notification-user-token", { token }));
    },
    deleteFCMToken(context, id) {
      return handleApi(axios.delete(`/api/notification-user-token/${id}`));
    }
  },
  modules: {
    namespaced: true,
    language,
    authentication,
    contactUs,
    faq,
    myProfile,
    until,
    application,
    companyMember,
    transactionHistory,
    dashboard
  },
  plugins: [createPersistedState()]
});
