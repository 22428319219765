import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
//import css
import "./assets/scss/index.css";
import "./assets/scss/index.scss";
import i18n from "@/locate/i18n.js";
import { resceiveMessage } from "./firebase/init";
const app = createApp(App);
app.use(store).use(router).use(ElementPlus).use(i18n).mount("#app");
resceiveMessage();
// try{
//   function requestPermission() {
//     console.log('Requesting permission...');
//     Notification.requestPermission().then((permission) => {
//       if (permission === 'granted') {
//         console.log('Notification permission granted.');
//       }
//     })
//   }
//   requestPermission()
// }catch(e){
//   console.log(e);
// }
