<template>
  <div
    class="page-nav-bar overflow-y-auto w-64 p-6 pt-0 flex flex-col -left-full fixed lg:left-0"
    :class="showMenu && 'is-active'"
  >
    <div
      class="w-10 h-10 flex justify-center items-center rounded-full bg-neutral-03 hover:bg-blue-background absolute right-2 top-2 lg:hidden"
    >
      <img class="w-8 h-8 cursor-pointer" src="@/assets/icons/active-icon/time-icon.svg" alt="" @click="toggleMenu" />
    </div>
    <div class="page-logo py-4">
      <img
        class="w-40 cursor-pointer"
        src="@/assets/images/defaultPageLogo.png"
        alt=""
        @click="changeRoute('dashboard')"
      />
    </div>
    <div class="page-menu flex flex-col grow justify-between">
      <el-menu
        active-text-color="#1B1B1B"
        background-color="#fff"
        class="el-menu-vertical-demo flex flex-col h-full justify-between"
        :default-active="currentRoute"
        text-color="#8D8D8D"
      >
        <div>
          <el-menu-item
            v-for="(item, idx) in menuByRole"
            :key="'menu' + idx"
            :index="item.routerName"
            :disabled="item.disable"
            @click="changeRoute(item.routerName)"
          >
            <img class="menu-active" :src="item.iconActive" alt="" />
            <img class="menu-inactive" :src="item.iconInactive" alt="" />
            <span class="menu-label">{{ $t(item.name) }}</span>
          </el-menu-item>
        </div>
        <div>
          <el-menu-item
            v-for="(item, idx) in menuFooter"
            :key="'menuFooter' + idx + menu.length"
            :index="item.routerName"
            :disabled="item.disable"
            @click="changeRoute(item.routerName)"
          >
            <img class="menu-active" :src="item.iconActive" alt="" />
            <img class="menu-inactive" :src="item.iconInactive" alt="" />
            <span class="menu-label">{{ $t(item.name) }}</span>
          </el-menu-item>
        </div>
      </el-menu>
      <div class="text-neutral-05 text-sm font-medium border-t border-solid border-neutral-02">
        <div
          class="flex gap-4 p-5 mt-2 cursor-pointer rounded hover:text-neutral-08 hover:bg-blue-background"
          @click="signOut"
        >
          <img src="@/assets/icons/log-out-icon.svg" alt="" />
          <span>{{ $t("sign_out.log_out") }}</span>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="showMenu"
    class="menu-overlay fixed top-0 left-0 bottom-0 right-0 z-10 lg:static"
    @click="showMenu = false"
  ></div>
  <div class="main-pages w-screen h-screen pl-0 lg:pl-64">
    <div class="bg-[#F3F3F3] h-full">
      <div class="page-header px-4 lg:px-8 py-4 flex justify-between items-center bg-white">
        <div class="font-bold text-xl text-neutral-08">
          <div class="flex items-center gap-4">
            <div
              class="w-10 h-10 flex justify-center items-center rounded-full bg-neutral-03 hover:bg-blue-background lg:hidden"
            >
              <img
                class="w-8 h-8 cursor-pointer"
                src="@/assets/icons/black-icon/menu-icon.svg"
                alt=""
                @click="toggleMenu"
              />
            </div>
            <span class="hidden lg:inline">{{ routeNames[currentRoute] && $t(routeNames[currentRoute]) }}</span>
            <img
              class="cursor-pointer w-28 inline lg:hidden"
              src="@/assets/images/defaultPageLogo.png"
              @click="changeRoute('dashboard')"
            />
          </div>
        </div>
        <div class="flex items-center gap-3 sm:gap-6">
          <div class="bell-icon cursor-pointer relative" @click.stop="readNotification">
            <img src="@/assets/icons/bell-icon.svg" alt="" />
            <img
              src="@/assets/icons/notification-icon/unseen-icon.svg"
              class="absolute right-2 top-2"
              :class="{ 'bell-unseen': !store.state.isReadNotification }"
              alt=""
            />
            <div
              v-show="showListNoti"
              @click.stop
              class="bell-dropdown absolute bg-white shadow-xl rounded-lg top-5 right-5 w-max max-w-4xl z-10 overflow-auto h-96"
            >
              <ListNotification
                ref="listNotification"
                size="small"
                @onDbClick="showListNoti = false"
              ></ListNotification>
            </div>
          </div>
          <div class="account-info flex items-center gap-3">
            <div class="account-avatar">
              <img
                class="h-10 w-10 border border-neutral-02 rounded-full"
                :src="userInfo.avatarImageUrl || require('@/assets/images/avatar-default.png')"
                alt=""
              />
            </div>
            <div class="account-profile relative">
              <div class="flex gap-3 items-center cursor-pointer">
                <span class="text-sm font-bold text-neutral-08 hidden sm:inline">
                  {{ userProfile.firstName || userInfo.firstName }}
                  {{ userProfile.lastName || userInfo.lastName }}
                </span>
                <img src="@/assets/icons/dropdown-icon.svg" alt="" />
              </div>
              <div ref="infoDropdown" class="menu-profile-dropdown absolute bg-white p-3 right-0 min-w-[248px] z-10">
                <div class="menu-profile-item flex gap-3 items-center px-4 py-3" @click="changeRoute('myProfile')">
                  <img src="@/assets/icons/profile-icon.svg" alt="" />
                  <span class="whitespace-nowrap">{{
                    userInfo.role === Roles.individual ? $t("dashboard.my_profile") : $t("profile.company_profile")
                  }}</span>
                </div>
                <div class="menu-profile-item flex gap-3 items-center px-4 py-3" @click="changeRoute('editProfile')">
                  <img src="@/assets/icons/edit-icon.svg" alt="" />
                  <span class="whitespace-nowrap">{{ $t("dashboard.edit_profile") }}</span>
                </div>
                <div class="menu-profile-item flex gap-3 items-center px-4 py-3" @click="changeRoute('changeEmail')">
                  <img src="@/assets/icons/edit-icon.svg" alt="" />
                  <span class="whitespace-nowrap">{{ $t("dashboard.change_email") }}</span>
                </div>
                <div class="menu-profile-item flex gap-3 items-center px-4 py-3" @click="changeRoute('changePassword')">
                  <img src="@/assets/icons/security-acitve-icon.svg" alt="" />
                  <span class="whitespace-nowrap">{{ $t("profile.security_information") }}</span>
                </div>
                <div
                  v-if="hasValidRole([Roles.business], userInfo.role)"
                  class="menu-profile-item flex gap-3 items-center px-4 py-3"
                  @click="changeRoute('companyMember')"
                >
                  <img src="@/assets/icons/menu-active/company-member-icon.svg" alt="" />
                  <span class="whitespace-nowrap">{{ $t("dashboard.company_members") }}</span>
                </div>
                <!-- <div class="menu-profile-item flex gap-3 items-center px-4 py-3">
                  <img src="@/assets/icons/menu-active/setting-icon.svg" alt="" />
                  <span class="whitespace-nowrap">{{ $t("dashboard.setting") }}</span>
                </div> -->
                <div class="menu-profile-item my-3 h-[1px] bg-neutral-02"></div>
                <div class="menu-profile-item flex gap-3 items-center px-4 py-3" @click="signOut">
                  <img src="@/assets/icons/log-out-icon-active.svg" alt="" />
                  <span class="whitespace-nowrap">{{ $t("sign_out.log_out") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-content p-4 lg:px-6 2xl:px-12 xl:pt-8">
        <div class="lg:hidden text-xl font-bold text-neutral-08 mb-4">
          {{ routeNames[currentRoute] && $t(routeNames[currentRoute]) }}
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ref, reactive, computed, watch, onMounted, onBeforeUnmount } from "vue";
import { Roles } from "@/constants/Role";
import { hasValidRole } from "@/untils/helper";
import ListNotification from "@/pages/loginedPages/Notification/ListNotification.vue";
import { ElNotification } from "element-plus";
import { removeFCMToken } from "@/firebase/init";
import STATUS from "@/constants/status";
const router = useRouter();
const route = useRoute();
const store = useStore();
const showMenu = ref(false);
const listNotification = ref(null);
const userInfo = computed(() => {
  return store.state.authentication.userInfo;
});
const newNotification = computed(() => {
  return store.state.newNotification;
});
const userProfile = computed(() => {
  return store.state.myProfile.userProfile;
});
const menuByRole = computed(() => {
  return menu.filter((item) => item.active);
});
const selectApplication = (data) => {
  if (data.importationType) {
    if (data.importationType === "PROMOTION") {
      router.push({
        name: "temporaryPromotionDetail",
        params: { id: data.applicationId }
      });
      return;
    }
    if (data.importationType === "EXPO") {
      router.push({
        name: "temporaryExpoDetail",
        params: { id: data.applicationId }
      });
      return;
    }
  } else {
    if (data.applicationStatus === STATUS.draft) {
      router.push({
        name: "editApplication",
        params: { id: data.applicationId }
      });
      return;
    }
    if (data.applicationStatus === STATUS.expired || data.applicationStatus === STATUS.renewal) {
      router.push({
        name: "renewDetailApplication",
        params: { id: data.applicationId }
      });
      return;
    }
    if (data.applicationStatus === STATUS.amend) {
      router.push({
        name: "amendDetailApplication",
        params: { id: data.applicationId }
      });
      return;
    }
    if (data.applicationStatus === STATUS.pending || data.applicationStatus === STATUS.update) {
      if (data.processingStage === STATUS.renewal) {
        router.push({
          name: "renewDetailApplication",
          params: { id: data.applicationId }
        });
        return;
      }
      if (data.processingStage === STATUS.amend) {
        router.push({
          name: "amendDetailApplication",
          params: { id: data.applicationId }
        });
        return;
      }
    }
    router.push({
      name: "detailApplication",
      params: { id: data.applicationId }
    });
  }
};
watch(newNotification, (newValue) => {
  store.commit("updateIsReadNotification", true);
  listNotification.value.getNotificationList();
  ElNotification({
    title: newValue.notification.title,
    message: newValue.notification.body,
    type: "success",
    onClick: () => {
      selectApplication(newValue.data);
      store.dispatch("readNotification", { id: newValue.data.notificationId });
    }
  });
});
const showListNoti = ref(false);
const readNotification = async () => {
  if (userInfo.value.accessToken && !showListNoti.value) {
    await listNotification.value.getNotificationList();
  }
  store.commit("updateIsReadNotification", false);
  showListNoti.value = !showListNoti.value;
};
onMounted(() => {
  if (userInfo.value.accessToken) {
    listNotification.value.getNotificationList();
  }
  document.addEventListener("click", () => {
    showListNoti.value = false;
  });
});
onBeforeUnmount(() => {
  document.removeEventListener("click", () => {
    showListNoti.value = false;
  });
});
const currentRoute = computed(() => route.name);
const routeNames = {
  dashboard: "menu.dashboard",
  notification: "menu.notification",
  newApplication: "menu.new_application",
  myProfile: "profile.account_settings",
  editProfile: "profile.account_settings",
  changePassword: "profile.account_settings",
  companyMember: "menu.company_members",
  myApplication: "menu.my_application",
  detailApplication: "application.detail_application",
  editApplication: "application.edit_application",
  dupplicateApplication: "application.duplicate_application",
  renewApplication: "menu.renewal_application",
  renewDetailApplication: "menu.renewal_application",
  amendApplicationList: "menu.amendment",
  amendDetailApplication: "menu.amendment",
  amendApplication: "menu.amendment",
  temporary: "menu.temporary_importation",
  temporaryPromotionDetail: "dashboard.promotion_detail",
  temporaryExpoDetail: "dashboard.expo_detail",
  editTemporaryPromotion: "dashboard.edit_promotion",
  editTemporaryExpo: "dashboard.edit_expo",
  createTemporary: "temporary_importation.create_temporary",
  transactionHistory: "menu.transaction_history",
  contactUs: "menu.contact_us",
  faqs: "menu.faqs"
};
const menu = reactive([
  {
    name: "menu.dashboard",
    iconActive: require("@/assets/icons/menu-active/dashboard-icon.svg"),
    iconInactive: require("@/assets/icons/menu-inactive/dashboard-icon.svg"),
    routerName: "dashboard",
    disable: false,
    active: true
  },
  {
    name: "menu.my_application",
    iconActive: require("@/assets/icons/menu-active/my-application-icon.svg"),
    iconInactive: require("@/assets/icons/menu-inactive/my-application-icon.svg"),
    routerName: "myApplication",
    disable:
      userInfo.value.firstLogin || !userInfo.value.acceptTermAndConditional || userInfo.value.userStatus !== "ACTIVE",
    active: true
  },
  {
    name: "menu.new_application",
    iconActive: require("@/assets/icons/menu-active/new-application-icon.svg"),
    iconInactive: require("@/assets/icons/menu-inactive/new-application-icon.svg"),
    routerName: "newApplication",
    disable:
      userInfo.value.firstLogin || !userInfo.value.acceptTermAndConditional || userInfo.value.userStatus !== "ACTIVE",
    active: true
  },
  {
    name: "menu.renewal_application",
    iconActive: require("@/assets/icons/menu-active/renew-application-icon.svg"),
    iconInactive: require("@/assets/icons/menu-inactive/renew-application-icon.svg"),
    disable:
      userInfo.value.firstLogin || !userInfo.value.acceptTermAndConditional || userInfo.value.userStatus !== "ACTIVE",
    routerName: "renewApplication",
    active: hasValidRole([Roles.business, Roles.companyMember], userInfo.value.role)
  },
  {
    name: "menu.amendment",
    iconActive: require("@/assets/icons/menu-active/amendment-icon.svg"),
    iconInactive: require("@/assets/icons/menu-inactive/amendment-icon.svg"),
    routerName: "amendApplicationList",
    disable:
      userInfo.value.firstLogin || !userInfo.value.acceptTermAndConditional || userInfo.value.userStatus !== "ACTIVE",
    active: hasValidRole([Roles.business, Roles.companyMember], userInfo.value.role)
  },
  {
    name: "menu.temporary_importation",
    iconActive: require("@/assets/icons/menu-active/temporary-icon.svg"),
    iconInactive: require("@/assets/icons/menu-inactive/temporary-icon.svg"),
    routerName: "temporary",
    disable:
      userInfo.value.firstLogin || !userInfo.value.acceptTermAndConditional || userInfo.value.userStatus !== "ACTIVE",
    active: hasValidRole([Roles.business, Roles.companyMember], userInfo.value.role)
  },
  {
    name: "menu.transaction_history",
    iconActive: require("@/assets/icons/menu-active/transaction-icon.svg"),
    iconInactive: require("@/assets/icons/menu-inactive/transaction-icon.svg"),
    routerName: "transactionHistory",
    disable:
      userInfo.value.firstLogin || !userInfo.value.acceptTermAndConditional || userInfo.value.userStatus !== "ACTIVE",
    active: true
  },
  {
    name: "menu.company_members",
    iconActive: require("@/assets/icons/menu-active/company-member-icon.svg"),
    iconInactive: require("@/assets/icons/menu-inactive/company-member-icon.svg"),
    routerName: "companyMember",
    disable:
      userInfo.value.firstLogin || !userInfo.value.acceptTermAndConditional || userInfo.value.userStatus !== "ACTIVE",
    active: hasValidRole([Roles.business, Roles.companyMember], userInfo.value.role)
  },
  {
    name: "menu.notification",
    iconActive: require("@/assets/icons/menu-active/notification-icon.svg"),
    iconInactive: require("@/assets/icons/menu-inactive/notification-icon.svg"),
    routerName: "notification",
    disable:
      userInfo.value.firstLogin || !userInfo.value.acceptTermAndConditional || userInfo.value.userStatus !== "ACTIVE",
    active: true
  }
]);
const menuFooter = reactive([
  // {
  //   name: "menu.settings",
  //   iconActive: require("@/assets/icons/menu-active/setting-icon.svg"),
  //   iconInactive: require("@/assets/icons/menu-inactive/setting-icon.svg"),
  //   disable: userInfo.value.firstLogin || !userInfo.value.acceptTermAndConditional || userInfo.value.userStatus !== 'ACTIVE',
  // },
  {
    name: "menu.contact_us",
    iconActive: require("@/assets/icons/menu-active/contact-icon.svg"),
    iconInactive: require("@/assets/icons/menu-inactive/contact-icon.svg"),
    routerName: "contactUs",
    disable:
      userInfo.value.firstLogin || !userInfo.value.acceptTermAndConditional || userInfo.value.userStatus !== "ACTIVE",
    active: true
  },
  {
    name: "menu.faqs",
    iconActive: require("@/assets/icons/menu-active/faq-icon.svg"),
    iconInactive: require("@/assets/icons/menu-inactive/faq-icon.svg"),
    routerName: "faqs",
    disable:
      userInfo.value.firstLogin || !userInfo.value.acceptTermAndConditional || userInfo.value.userStatus !== "ACTIVE",
    active: true
  }
]);
const signOut = () => {
  store.commit("toggleLoading", true);
  store
    .dispatch("logOut")
    .then((response) => {
      removeFCMToken();
      router.push({ name: "getStarted" });
    })
    .finally(() => store.commit("toggleLoading", false));
};
const changeRoute = (routerName) => {
  showMenu.value = false;
  router.push({ name: routerName });
};
const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};
</script>
<style lang="scss" scoped>
.page-header {
  height: 80px;
}

.page-content {
  height: calc(100% - 80px);
  overflow: auto;
}

.page-nav-bar {
  transition: all 0.3s ease-in-out;
  @apply bg-white top-0 bottom-0;
  z-index: 11;

  &.is-active {
    left: 0;
  }
}

.menu-overlay {
  background: rgba($color: #000000, $alpha: 0.5);
  transition: all 0.3s ease-in-out;
}

.account-profile {
  .menu-profile-dropdown {
    display: none;
  }

  &:hover {
    .menu-profile-dropdown {
      display: block;
    }
  }
}

.menu-profile-dropdown {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  top: 100%;
  transition: all 0.5s ease-in-out;

  .menu-profile-item {
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      @apply bg-blue-background;
    }
  }
}

.bell-unseen {
  @apply hidden;
}

.bell-icon {
  @apply h-10 w-10 bg-neutral-01 rounded-full flex items-center justify-center;

  &:hover {
    @apply bg-blue-background;

    img {
      animation: shake 0.5s ease-in-out infinite;
    }
  }

  .bell-dropdown {
    @apply -right-20 min-[500px]:-right-16 top-3/4;
    max-width: min(90vw, 580px);
  }
}

@keyframes shake {
  0% {
    transform: rotate(30deg);
  }

  50% {
    transform: rotate(-30deg);
  }

  100% {
    transform: rotate(30deg);
  }
}
</style>
