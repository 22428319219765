import STATUS from "@/constants/status";
import router from "@/router";
import store from "../store";
import { Roles } from "@/constants/Role";

const redirectAppDetail = (row) => {
  if (row.importationType) {
    if (row.importationType === "PROMOTION") {
      router.push({
        name: "temporaryPromotionDetail",
        params: { id: row.appId }
      });
      return;
    }
    if (row.importationType === "EXPO") {
      router.push({ name: "temporaryExpoDetail", params: { id: row.appId } });
      return;
    }
  } else {
    if (row.status === STATUS.draft) {
      router.push({ name: "editApplication", params: { id: row.appId } });
      return;
    }
    if (
      (row.status === STATUS.expired && store.state.authentication.userInfo.role !== Roles.individual) ||
      row.status === STATUS.renewed
    ) {
      router.push({
        name: "renewDetailApplication",
        params: { id: row.appId }
      });
      return;
    }
    if (row.status === STATUS.amend) {
      router.push({
        name: "amendDetailApplication",
        params: { id: row.appId }
      });
      return;
    }
    if (row.status === STATUS.pending || row.status === STATUS.update || STATUS.rejected) {
      if (row.processingStage === STATUS.renewal) {
        router.push({
          name: "renewDetailApplication",
          params: { id: row.appId }
        });
        return;
      }
      if (row.processingStage === STATUS.amend) {
        router.push({
          name: "amendDetailApplication",
          params: { id: row.appId }
        });
        return;
      }
    }
    router.push({ name: "detailApplication", params: { id: row.appId } });
  }
};

export default redirectAppDetail;
