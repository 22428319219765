<template>
  <div class="menu-list">
    <div class="menu-item text-neutral-08" @click="redirectTo('publicFaqs')">FAQs</div>
    <div class="menu-item text-neutral-08" @click="redirectTo('publicContactUs')">Contact Us</div>
    <div class="vertical-line h-full w-[1px] bg-neutral-06 hidden md:block"></div>
    <div v-if="route.name !== 'signIn'" class="menu-item text-neutral-08" @click="redirectTo('signIn')">
      {{ $t("sign_in.log_in") }}
    </div>
    <div v-if="route.name !== 'signUp'" class="menu-item text-neutral-08" @click="redirectTo('signUp')">
      <el-button type="primary" class="warning-button text-sm h-full !px-4 !py-3"
        >{{ $t("get_started.create_account") }}
      </el-button>
    </div>
  </div>
</template>
<script setup>
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
const router = useRouter();
const route = useRoute();
const store = useStore();
const redirectTo = (name) => {
  router.push({ name: name });
};
const changeLanguage = (value) => {
  store.commit("changeLanguge", value);
};
</script>
<style lang="scss" scoped>
.menu-list {
  @apply flex items-center gap-6 lg:gap-11 h-11;
}
.menu-item {
  @apply text-label text-neutral-08;
  cursor: pointer;
}
</style>
